import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/events/:state?',
    name: 'events',
    component: () => import('../views/EventsView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/event/:event',
    name: 'eventDetails',
    component: () => import('../views/EventDetailsview.vue')
  },
  {
    path: '/category/:slug',
    name: 'AllSinglePage',
    component: () => import('../views/AllSinglePage.vue')
  },
  /* new  pages */
  {
    path: '/:url_slug',
    name: 'NewPage',
    component: () => import('../views/NewPage.vue')
  },
  {
    path: '/post/:slug',
    name: 'PostDetails',
    component: () => import('../views/PostDetails.vue')
  },
  // {
  //   path: '/Publications',
  //   name: 'Publications',
  //   component: () => import('../views/Publications.vue')
  // },
  // {
  //   path: '/MediaCenter',
  //   name: 'MediaCenter',
  //   component: () => import('../views/MediaCenter.vue')
  // },
  // {
  //   path: '/NewsDetails',
  //   name: 'NewsDetails',
  //   component: () => import('../views/NewsDetails.vue')
  // },
  // {
  //   path: '/PublicationsDetails',
  //   name: 'PublicationsDetails',
  //   component: () => import('../views/PublicationsDetails.vue')
  // },
  // {
  //   path: '/CaseStudiesDetails',
  //   name: 'CaseStudiesDetails',
  //   component: () => import('../views/CaseStudiesDetails.vue')
  // },
  {
    path: '/case/:id',
    name: 'CaseDetails',
    component: () => import('../views/CaseDetails.vue')
  },
  {
    path: '/cases',
    name: 'CasesView',
    component: () => import('../views/CasesView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
