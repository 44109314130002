<template>
    <div class="filter responsive_filter">
        <form action="" class="large_screen">
            <div class="accordion" id="filterAccordion">
                <div class="card">
                    <div id="headingCountry">
                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseCountry" aria-expanded="false" aria-controls="collapseCountry">
                            {{ $t("filter.Province") }}
                        </button>
                    </div>
                    <div id="collapseCountry" class="collapse" aria-labelledby="headingCountry"
                        data-bs-parent="#filterAccordion">
                        <div class="card-body">
                            <ul class="countries">
                                <li v-for="state in states" :key="state.id" :id="state.id" class="state"
                                    :class="{ 'checked': selectedStates.includes(state.id) }" @click="checkState(state.id)">
                                    <span>{{ state.name }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div id="headingCovenant">
                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseCovenant" aria-expanded="false" aria-controls="collapseCovenant">
                            {{ $t("filter.Facility") }}
                        </button>
                    </div>
                    <div id="collapseCovenant" class="collapse" aria-labelledby="headingCovenant"
                        data-bs-parent="#filterAccordion">
                        <div class="card-body">
                            <ul class="covenants">
                                <li v-for="facility in facilities " :key="facility.id" :id="facility.id" class="covenant"
                                    :class="{ 'checked': selectedFacilities.includes(facility.id) }"
                                    @click="checkfacility(facility.id)"><span>{{ facility.title }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div id="headingTechnology">
                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTechnology" aria-expanded="false" aria-controls="collapseTechnology">
                            {{ $t("filter.Technology_Adopted") }}
                        </button>
                    </div>
                    <div id="collapseTechnology" class="collapse" aria-labelledby="headingTechnology"
                        data-bs-parent="#filterAccordion">
                        <div class="card-body">
                            <ul class="covenants">
                                <li v-for="Technology in Technologys " :key="Technology.id" :id="Technology.id"
                                    class="covenant" :class="{ 'checked': selectedTechnologys.includes(Technology.id) }"
                                    @click="checkTechnology(Technology.id)"><span>{{ Technology.title }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div id="headingWaste">
                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseWaste" aria-expanded="false" aria-controls="collapseWaste">
                            {{ $t("filter.Waste_Managed") }}
                        </button>
                    </div>
                    <div id="collapseWaste" class="collapse" aria-labelledby="headingWaste"
                        data-bs-parent="#filterAccordion">
                        <div class="card-body">
                            <ul class="covenants">
                                <li v-for="Waste in Wastes " :key="Waste.id" :id="Waste.id" class="covenant"
                                    :class="{ 'checked': selectedWastes.includes(Waste.id) }" @click="checkWaste(Waste.id)">
                                    <span>{{ Waste.title }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <VueDatePicker v-model="date" :locale="($i18n.locale == 'ar') ? 'ar' : 'en'"
                    :placeholder="$t('filter.date')" auto-apply range :preset-ranges="presetRanges" week-start="0" dark
                    :enable-time-picker="false" hide-input-icon input-class-name="date-picker"
                    menu-class-name="date-picker-menu" calendar-cell-class-name="date-picker-cell"
                    calendar-class-name="date-pocker-calendar">
                    <span @click="presetDateRange(range)">{{ $t('label') }}</span>
                </VueDatePicker>
            </div>
            <button @click="filter" type="button" class="accordion-btn justify-content-center mt-2 filter_button">{{
                $t("filter.apply")
            }}</button>
            <div class="col-12 text-center">
                <a href="/cases" class="See_More"> {{ $t("filter.See_More") }} <font-awesome-icon
                        icon="a-solid fa-arrow-right-long"></font-awesome-icon></a>
            </div>
        </form>

        <!-- responsive -->
        <form action="" class="small_screen">
            <div class="accordion accordion_small" id="small_filterAccordion">
                <div class="card">
                    <div id="headingGender_small">
                        <button class="accordion-btn title_filter" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseGender_small" aria-expanded="true"
                            aria-controls="collapseGender_small">
                            {{ $t("filter.Filter") }}
                        </button>
                    </div>
                    <div id="collapseGender_small" class="collapse" aria-labelledby="headingGender_small"
                        data-bs-parent="#small_filterAccordion">
                        <div class="card-body">
                            <div class="accordion" id="filterAccordion1">
                                <!-- <div class="card">
                                    <div id="headingGender_small">
                                        <button class="accordion-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseGender" aria-expanded="true" aria-controls="collapseGender">
                                            {{ $t("filter.gender") }}
                                        </button>
                                    </div>
                                    <div id="collapseGender" class="collapse" aria-labelledby="headingGender_small"
                                        data-bs-parent="#filterAccordion1">
                                        <div class="card-body">
                                            <ul class="genders">
                                                <li v-for="gender in genders" :key="gender.id" :id="gender.id" class="gender"
                                                    :class="{ 'checked': selectedGenders.includes(gender.id) }"
                                                    @click="checkGender(gender.id)"><span>{{ gender.title }}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="card">
                                    <div id="headingCountry1">
                                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseCountry1" aria-expanded="false"
                                            aria-controls="collapseCountry1">
                                            {{ $t("filter.state") }}
                                        </button>
                                    </div>
                                    <div id="collapseCountry1" class="collapse" aria-labelledby="headingCountry1"
                                        data-bs-parent="#filterAccordion1">
                                        <div class="card-body">
                                            <ul class="countries">
                                                <li v-for="state in states" :key="state.id" :id="state.id" class="state"
                                                    :class="{ 'checked': selectedStates.includes(state.id) }"
                                                    @click="checkState(state.id)">
                                                    <span>{{ state.name }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div id="headingCovenant1">
                                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseCovenant1" aria-expanded="false"
                                            aria-controls="collapseCovenant1">
                                            {{ $t("filter.Facility") }}
                                        </button>
                                    </div>
                                    <div id="collapseCovenant1" class="collapse" aria-labelledby="headingCovenant1"
                                        data-bs-parent="#filterAccordion1">
                                        <div class="card-body">
                                            <ul class="covenants">
                                                <li v-for="facility in facilities " :key="facility.id" :id="facility.id"
                                                    class="covenant"
                                                    :class="{ 'checked': selectedFacilities.includes(facility.id) }"
                                                    @click="checkfacility(facility.id)"><span>{{ facility.title }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div id="headingTechnology1">
                                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTechnology1" aria-expanded="false"
                                            aria-controls="collapseTechnology1">
                                            {{ $t("filter.Technology_Adopted") }}
                                        </button>
                                    </div>
                                    <div id="collapseTechnology1" class="collapse" aria-labelledby="headingTechnology1"
                                        data-bs-parent="#filterAccordion1">
                                        <div class="card-body">
                                            <ul class="covenants">
                                                <li v-for="Technology in Technologys " :key="Technology.id"
                                                    :id="Technology.id" class="covenant"
                                                    :class="{ 'checked': selectedTechnologys.includes(Technology.id) }"
                                                    @click="checkTechnology(Technology.id)"><span>{{ Technology.title
                                                    }}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div id="headingWaste1">
                                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseWaste1" aria-expanded="false"
                                            aria-controls="collapseWaste1">
                                            {{ $t("filter.Waste_Managed") }}
                                        </button>
                                    </div>
                                    <div id="collapseWaste1" class="collapse" aria-labelledby="headingWaste1"
                                        data-bs-parent="#filterAccordion1">
                                        <div class="card-body">
                                            <ul class="covenants">
                                                <li v-for="Waste in Wastes " :key="Waste.id" :id="Waste.id" class="covenant"
                                                    :class="{ 'checked': selectedWastes.includes(Waste.id) }"
                                                    @click="checkWaste(Waste.id)"><span>{{ Waste.title }}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card">
                                    <div id="headingAge1">
                                        <button class="accordion-btn collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseAge1" aria-expanded="false" aria-controls="collapseAge1">
                                            {{ $t("filter.age") }}
                                        </button>
                                    </div>
                                    <div id="collapseAge1" class="collapse" aria-labelledby="headingAge1" data-bs-parent="#filterAccordion1">
                                        <div class="card-body slider-body">
                                            <Slider v-model="slider.value" v-bind="slider"></Slider>
                                        </div>
                                    </div>
                                </div> -->
                                <VueDatePicker class="date_picker_style" v-model="date"
                                    :locale="($i18n.locale == 'ar') ? 'ar' : 'en'" :placeholder="$t('filter.date')"
                                    auto-apply range :preset-ranges="presetRanges" week-start="0" dark
                                    :enable-time-picker="false" hide-input-icon input-class-name="date-picker"
                                    menu-class-name="date-picker-menu" calendar-cell-class-name="date-picker-cell"
                                    calendar-class-name="date-pocker-calendar">
                                    <span @click="presetDateRange(range)">{{ $t('label') }}</span>
                                </VueDatePicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button @click="filter" type="button" class="accordion-btn btn_small justify-content-center mt-2">{{
                $t("filter.apply")
            }}</button>
        </form>

    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import Slider from '@vueform/slider';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, endOfWeek, startOfWeek, subMonths } from 'date-fns';
import '@vuepic/vue-datepicker/dist/main.css';
const base_url = process.env.VUE_APP_BASE_URL;

export default {
    name: "Filter",
    components: {
        VueDatePicker,
        onMounted,
        Slider,
    },
    setup() {
        // Date picker
        const date = ref();
        const presetRanges = ref([
            { label: 'Today', range: [new Date(), new Date()] },
            { label: 'This Week', range: [startOfWeek(new Date()), endOfWeek(new Date())] },
            { label: 'This Month', range: [startOfMonth(new Date()), endOfMonth(new Date())] },
            { label: 'Last Month', range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))], },
            { label: 'This Year', range: [startOfYear(new Date()), endOfYear(new Date())] },
        ]);
        onMounted(() => {
            // const startDate = new Date();
            // const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
            // date.value = [startDate, endDate];
        })
        return { date, presetRanges }
    },
    data() {
        return {
            locale: localStorage.getItem('lang'),
            states: [],
            // genders: [],
            // covenants: [],
            facilities: [],
            Technologys: [],
            Wastes: [],
            ages: [],
            selectedStates: [],
            // selectedGenders: [],
            // selectedCovenants: [],
            selectedFacilities: [],
            selectedTechnologys: [],
            selectedWastes: [],
            // selectedAges: [],
            slider: {
                value: [0, 0],
                merge: 5,
                direction: "ltr"
            },
            rtl: localStorage.getItem('lang') == 'ar'
        }
    },
    methods: {
        filterCheck(array, value) {
            if (array.includes(value)) {
                array = array.filter(function (filtered) {
                    return filtered !== value;
                });
            } else {
                array.push(value);
            };
            return array
        },
        checkGender(genderID) {
            this.selectedGenders = this.filterCheck(this.selectedGenders, genderID);
        },
        checkState(stateID) {
            this.selectedStates = this.filterCheck(this.selectedStates, stateID);
        },
        // checkCovenant(covenantID) {
        //     this.selectedCovenants = this.filterCheck(this.selectedCovenants, covenantID);
        // },
        checkfacility(facilityID) {
            this.selectedFacilities = this.filterCheck(this.selectedFacilities, facilityID);
        },
        checkTechnology(TechnologyID) {
            this.selectedTechnologys = this.filterCheck(this.selectedTechnologys, TechnologyID);
        },
        checkWaste(WasteID) {
            this.selectedWastes = this.filterCheck(this.selectedWastes, WasteID);
        },
        checkAge(ageID) {
            this.selectedAges = this.filterCheck(this.selectedAges, ageID);
        },

        // Fetching data from API
        // async fetchGenders() {
        //     const res = await fetch(base_url + '/get_genders?lang_id=2')
        //     const genders = await res.json()
        //     return genders.data
        // },
        async fetchStates() {
            // let locale= localStorage.getItem('lang');
            // let params = new URLSearchParams();
            // params.append('locale', locale);

            const res = await fetch(base_url + '/get_states?country_id=65&')
            const states = await res.json()
            return states.data
        },
        async fetchFacilities() {
            let locale = localStorage.getItem('lang');
            let params = new URLSearchParams();
            params.append('locale', locale);

            const res = await fetch(base_url + '/get_facilities?' + params)
            const facilities = await res.json()
            return facilities.data
        },
        async fetchTechnologys() {
            let locale = localStorage.getItem('lang');
            let params = new URLSearchParams();
            params.append('locale', locale);

            const res = await fetch(base_url + '/get_technologies?' + params)
            const Technologys = await res.json()
            return Technologys.data
        },
        async fetchWastes() {
            let locale = localStorage.getItem('lang');
            let params = new URLSearchParams();
            params.append('locale', locale);

            const res = await fetch(base_url + '/get_waste_managed?' + params)
            const Wastes = await res.json()
            return Wastes.data
        },
        // async fetchCovenants() {
        //     const res = await fetch(base_url + '/get_covenants?lang_id=2')
        //     const covenants = await res.json()
        //     return covenants.data
        // },
        // async fetchAges() {
        //     const res = await fetch(base_url + '/helpers/get_age_range?lang_id=2')
        //     const ages = await res.json()
        //     return ages
        // },

        // Send data to Parent 
        filter() {
            this.$emit('filtered', this.selectedStates, this.selectedFacilities, this.selectedTechnologys, this.selectedWastes, this.date)
            console.log(this.selectedFacilities + " " + " roji");
        }
    },
    async created() {
        // this.genders = await this.fetchGenders()
        this.states = await this.fetchStates()
        this.facilities = await this.fetchFacilities()
        this.Technologys = await this.fetchTechnologys()
        this.Wastes = await this.fetchWastes()
        // this.covenants = await this.fetchCovenants()
        // this.ages = await this.fetchAges().then(ages => {
        //     this.slider.value = [ages[0].min_age, ages[0].max_age]
        //     this.slider.min = ages[0].min_age
        //     this.slider.max = ages[0].max_age
        // })
        this.slider.direction = this.rtl ? 'rtl' : 'ltr'
    }
}

</script>

<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.filter {
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.3s;
    z-index: 50;
    width: 360px;
    max-height: 100%;
    background-color: rgb(28 30 83 / 80%);
    padding: 10px 32px 20px;
    height: 100%;
    display: inline-grid;
    align-items: end;
}

.filter .See_More {
    color: #fff;
    font-size: 15px;
}

.filter .See_More svg {
    padding: 0 9px;
}

.rtl .filter {
    position: absolute;
    bottom: 20px;
    right: 0px;
    width: 360px;
    transition: 0.3s;
    z-index: 50;
    max-height: 100%;
}

@media(max-width:768px) {
    .rtl .filter {
        right: 20px;
    }
}

.filter * {
    white-space: nowrap;
}

.filter .card-body {
    padding: 0;
    background-color: #f4f5ff;
}

.filter .card-body.slider-body {
    padding: 1.5rem;
}

.filter ul {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #23282b10;
    max-height: 200px;
    overflow-x: visible;
    overflow-y: auto;
}

.filter ul li {
    text-align: left;
    border-bottom: 1px solid #ffffff0a;
    cursor: pointer;

}

.filter ul li span {
    display: block;
    padding: .75rem 1.5rem;
    opacity: 0.6;
    transition: 0.3s;
    color: var(--color);
}

.filter ul li.checked span {
    padding: .75rem 2rem;
    opacity: 1;
    color: var(--color);
    font-weight: 600;
}

.filter .accordion {
    border-radius: 0;
}

.filter .accordion .card {
    border: none;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    margin-bottom: 18px;
}

.filter .accordion-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    color: var(--lightGrey);
    background-color: var(--lightColor) !important;
    color: white !important;
    border-radius: 0;
    width: 100%;
    border: none;
    box-shadow: 0px 4px 20px 0px #00000040;
    border: 2px solid #E5E5E5;
}

.accordion-btn.filter_button {
    background-color: var(--mainColor) !important;
    border: 0;
    color: var(--Color) !important;
    font-weight: 700;
    margin-bottom: 20px;
}

.rtl .filter ul li {
    text-align: right;
}

/*  responsive */
.small_screen {
    display: none;
}

.small_screen {}

@media(max-width:768px) {
    .small_screen {
        display: flex;
        align-items: end;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -20px;
        right: -20px;
    }

    .responsive_filter {
        background-color: transparent;
        height: 0;
    }

    .small_screen .card {
        margin-bottom: 0 !important;
    }

    .filter .small_screen .accordion-btn {
        border-color: transparent !important;
    }

    .small_screen .btn_small {
        max-width: 25%;
        min-width: 105px;
        background-color: var(--mainColor) !important;
        color: var(--color) !important;
        font-weight: bold;
    }

    .small_screen .accordion_small {
        width: 75%;
    }

    .large_screen {
        display: none !important;
    }

    .responsive_filter {
        width: 100% !important;
    }
    .responsive_filter .title_filter{
        background-color: #272857 !important;
    }
}
</style>