<template>
    <div class="search-wrapper">
        <Button @btnClick="hideSearch()" text="" icon="fa-solid fa-times" textColor="white" />
        <form action="https://disappearance.egyclimate.net" method="get">
            <input type="text" name="s" placeholder="Search...">
        </form>
    </div>
</template>

<script>
import Button from "./Button.vue";
export default {
    name: 'Search',
    components: {
        Button,
    },
    methods: {
        hideSearch() {
            document.querySelector('.search-wrapper').classList.remove('show');
        }
    },
}
</script>

<style scoped>
.search-wrapper {
    position: fixed;
    transform: scale(0);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000cc;
    padding: 0 15%;
    opacity: 0;
    transition: 0.3s ease-in-out;
    z-index: 1070;
}

.search-wrapper.show {
    opacity: 1;
    transform: scale(1);
}

.search-wrapper form {
    width: 100%;
}

.search-wrapper input {
    background: none;
    border: none;
    border-bottom: 1px solid white;
    width: 100%;
    font-size: 50px;
    text-align: center;
    line-height: 1.5;
    padding: 0;
    color: #fff;
}

.search-wrapper input:focus {
    outline: none;
}

.search-wrapper .btn {
    position: absolute;
    left: 0;
    top: 50%;
    background: none !important;
    color: #7a7a7a !important;
    font-size: 20px !important;
    border: none !important;
}
</style>