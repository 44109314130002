<template>
    <div class="map-view">
        <Filter @filtered="filterAll" />
        <!-- <Panel :class="{ 'show': panelShow }" :events="events" /> -->
        <GoogleMap api-key="AIzaSyBKds2uWBtwsGgHwYQg2rh17S0oIu1jpZs" class="map"
            style="width: 100%; height: calc(100vh - 75px)" :styles="mapStyle" :center="center" :zoom="zoom"
            :mapTypeControl="false">
            <MarkerCluster v-if="enable_clustering.value == 1">
                <Marker v-for="location in locations" :key="location.id"
                    :options="{ position: { lat: location.state.latitude, lng: location.state.longitude } }"
                    @click="renderPanel(location.id)">
                    <InfoWindow v-if="events2" @visible="showPanel()" @closeclick="hidePanel()"
                        :ref="(el) => (infoWindows[location.state.id] = el)">
                        <div class="content new_details">
                            <img :src="storage_url + events2.image" alt="">
                            <div class="px-4 height_detail mt-3">
                                <p class="description">{{ events2.description }}</p>
                                <table>
                                    <tr>
                                        <td>{{$t ('filter.Province')}}</td>
                                        <td v-if="events2.state">{{ events2.state.name }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{$t ('filter.date')}}</td>
                                        <td>{{ events2.date }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{$t ('filter.Kind_facility')}}</td>
                                        <td v-if="events2.facility">{{ events2.facility.title }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{$t ('filter.Type_waste')}}</td>
                                        <td v-if="events2.waste_managed">{{ events2.waste_managed.title }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{$t ('filter.Technology_adopted')}}</td>
                                        <td v-if="events2.technology">{{ events2.technology.title }}</td>
                                    </tr>
                                </table>
                                <div id="bodyContent">
                                    <div class="row button_arrow mb-2 text-align: center;">
                                        <div class="col-md-12">
                                            <button class="btn-small">
                                                <router-link :to="'../case/' +  events2.id">{{ $t('map.details')
                                                }} <font-awesome-icon
                                                        icon="far fa-arrow-alt-circle-right"></font-awesome-icon></router-link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </InfoWindow>
                </Marker>
            </MarkerCluster>
            <div v-else>
                <Marker v-for="location in locations" :key="location.id"
                    :options="{ position: { lat: location.state.latitude, lng: location.state.longitude } }"
                    @click="renderPanel(location.id)">
                    <InfoWindow v-if="events2" @visible="showPanel()" @closeclick="hidePanel()"
                        :ref="(el) => (infoWindows[location.state.id] = el)">
                        <div class="content new_details">
                            <img :src="storage_url + events2.image" alt="">
                            <div class="px-4 height_detail mt-3">
                                <p class="description">{{ events2.description }}</p>
                                <table>
                                    <tr>
                                        <td>{{$t ('filter.Province')}}</td>
                                        <td v-if="events2.state">{{ events2.state.name }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{$t ('filter.date')}}</td>
                                        <td>{{ events2.date }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{$t ('filter.Kind_facility')}}</td>
                                        <td v-if="events2.facility">{{ events2.facility.title }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{$t ('filter.Type_waste')}}</td>
                                        <td v-if="events2.waste_managed">{{ events2.waste_managed.title }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{$t ('filter.Technology_adopted')}}</td>
                                        <td v-if="events2.technology">{{ events2.technology.title }}</td>
                                    </tr>
                                </table>
                                <div id="bodyContent">
                                    <div class="row button_arrow mb-2 text-align: center;">
                                        <div class="col-md-12">
                                            <button class="btn-small">
                                                <router-link :to="'../case/' +  events2.id">{{ $t('map.details')
                                                }} <font-awesome-icon
                                                        icon="far fa-arrow-alt-circle-right"></font-awesome-icon></router-link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </InfoWindow>
                </Marker>
            </div>
            <Polygon :options="bermudaTriangle" />
        </GoogleMap>
    </div>
</template>

<script>

import axios from 'axios'
import { defineComponent } from "vue";
import Filter from "./Filter.vue";
import Panel from "./Panel.vue";
import { GoogleMap, MarkerCluster, Marker, InfoWindow, Polygon } from "vue3-google-map";
import moment from 'moment';
import triangleCoords from "../assets/city.js";
const base_url = process.env.VUE_APP_BASE_URL;


export default defineComponent({
    components: {
        Panel,
        Filter,
        GoogleMap,
        MarkerCluster,
        Marker,
        InfoWindow,
        Polygon,
    },
    data() {
        return {
            storage_url: process.env.VUE_APP_BASE_URL_WEB + "/storage",
            panelShow: false,
            openMarker: null,
            events: [],
            locations: [],
            // filteredGenders: [],
            filteredStates: [],
            filteredFacilities: [],
            filteredTechnologys: [],
            filteredWastes: [],
            // filteredCovenants: [],
            startDate: "",
            endDate: "",
            // startAge: "",
            // endAge: "",
            infoWindows: [],
            triangleCoords,
            bermudaTriangle: "",
            zoom: 6,
            center: { lat: 27.156486, lng: 30.491588 },
            enable_clustering: 0,
            events2:[],
            event,
        }
    },
    beforeMount() {
        this.get_enable_clustering();
    },
    methods: {
        showPanel() {
            this.panelShow = true
        },
        hidePanel() {
            this.panelShow = false
        },
        closeInfoWindows(except) {
            this.infoWindows.forEach((ref, index) => {
                if (index !== except) ref.infoWindow.close();
            });
        },

        // Render cases to side panel
        // async fetchCases(stateID) {
        //     let params = new URLSearchParams();
        //     params.append('filter[state_id]', this.stateId);
        //     const res = await fetch(base_url + '/cases/get_cases');
        //     const events = await res.json();
        //     return events.data;
            
        // },
         
       
        // Render filtered locations
        async fetchLocations() {
            // this.closeInfoWindows(null);
            this.infoWindows = [];
            let params = new URLSearchParams();
            // params.append('filter[gender_id]', this.filteredGenders);
            params.append('filter[state_id]', this.filteredStates);
            params.append('filter[facility_id]', this.filteredFacilities);
            params.append('filter[technology_id ]', this.filteredTechnologys);
            params.append('filter[waste_managed_id]', this.filteredWastes);
            params.append('filter[disappearance_date_from]', this.startDate);
            params.append('filter[disappearance_date_to]', this.endDate);
            // params.append('filter[age_from]', this.startAge);
            // params.append('filter[age_to]', this.endAge);

            const res = await fetch(base_url + '/cases/get_summary?' + params)
            const locations = await res.json()
            return locations.data
        },

        async filterAll(states, facilities, technologys, wastes, date = [],) {
            if (date == null) date = [];
            // this.filteredGenders = genders;
            this.filteredStates = states;
            this.filteredFacilities = facilities;
            this.filteredTechnologys = technologys;
            this.filteredWastes = wastes;
            // this.filteredCovenants = covenants;
            this.startDate = date[0] ? moment(date[0]).format("YYYY-MM-DD") : '';
            this.endDate = date[1] ? moment(date[1]).format("YYYY-MM-DD") : '';
            // this.startAge = ages[0];
            // this.endAge = ages[1];
            this.locations = await this.fetchLocations();

            let obj = [];
            const coordinates = [];

            for (let index = 0; index < this.filteredStates.length; index++) {
                const element = this.filteredStates[index];
                obj[index] = triangleCoords.find((o, i) => {
                    if (o.city === element) {
                        this.center = o.center;
                        this.zoom = 8;
                        return o.coordinates;
                    }
                });
            }
            var nameArray = obj.map(function (el) { return el.coordinates; });

            nameArray.forEach(function (item, i) {
                coordinates[i] = buildCoordinatesArrayFromString(item);
            });

            if (this.filteredStates.length > 1) {
                this.center = { lat: 27.156486, lng: 30.491588 };
                this.zoom = 6;
            }

            this.bermudaTriangle = {
                paths: coordinates,
                strokeColor: "#1ebb69",
                strokeOpacity: 0.5,
                strokeWeight: 2,
                fillColor: "#1ebb69",
                fillOpacity: .7,
            };
        },

        get_enable_clustering() {
            axios
                .get(base_url + "/get_setting/enable_clustering")
                .then((response) => {
                    this.enable_clustering = response.data.data;

                })
        },

        async fetchEvent(caseId) {
            const res = await fetch(base_url + "/cases/get_case/" + caseId);
            const event = await res.json();
            this.events2 =  event.data;
        },
        transform(array) {
            return array.map(itm => itm.title).join(', ');
        },

        async renderPanel(caseId) {
            this.closeInfoWindows(caseId)
            this.fetchEvent(caseId)
        },
    },

    async created() {
        this.locations = await this.fetchLocations();
    },

    setup() {
        //const center = { lat: 27.156486, lng: 30.491588 };
        const mapStyle = [
            {
                "featureType": "all",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "weight": "2.00"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#9c9c9c"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#7b7b7b"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46bcec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#c8d7d4"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#070707"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            }
        ];


        return { mapStyle };
    },

    mounted() {
        const coordinates = [];
        // const triangleCoords = [];
        var nameArray = triangleCoords.map(function (el) { return el.coordinates; });
        nameArray.forEach(function (item, i) {
            coordinates[i] = buildCoordinatesArrayFromString(item);
        });

        this.bermudaTriangle = {
            paths: coordinates,
            strokeColor: "#b41412",
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: "#b41412",
            fillOpacity: 0,

        };
    }
});

function buildCoordinatesArrayFromString(MultiGeometryCoordinates) {
    var finalData = [];
    var grouped = MultiGeometryCoordinates.split(" ");

    grouped.forEach(function (item, i) {
        let a = item.trim().split(',');

        finalData.push({
            lng: parseFloat(a[0]),
            lat: parseFloat(a[1])
        });
    });

    return finalData;
}
</script>

<style scoped>
.map-view {
    display: flex;
    position: relative;
}

.map {
    margin-right: 0;
    transition: 0.3s;
    width: 100%;
}

.panel.show+.map {
    margin-right: 300px;
}

.rtl .panel.show+.map {
    margin-right: 0;
    margin-left: 300px;
}
</style>
