<template>
    <footer>
        <div class="footer">
            <div class="container">
                <div class="row m-0">
                    <div class="col-md-6">
                        <a :href="footer_sections_left.title_link?.value">
                            <h1>{{ footer_sections_left.title?.value }}</h1>
                        </a>
                        <p>{{ footer_sections_left.text?.value }}</p>
                        <br>
                        <div class="contact_details mt-4">
                            <div class="row m-0">
                                <div class="col-md-6">
                                    <h3>{{ $t("email_me") }}</h3> 
                                    <span><a :href="('mailto:' + contact_data.value)">{{contact_data.value}}</a></span>
                                </div>
                                <div class="col-md-6">
                                    <h3>{{ $t("call_us") }}</h3>
                                    <span><a class="tel" :href="('tel:' + phone_data.value)">{{phone_data.value}}</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <h4 class="mt-3">{{ footer_sections_right.title?.value }}</h4>
                        <h5>{{ footer_sections_right.text?.value }}</h5>
                        <ul class="social_media">
                            <li v-for="social_media in footer_social_media?.channel?.extra" :key="social_media.key">
                                <a :href="social_media.link"> <font-awesome-icon :icon="['fa-brands', social_media.icon]" /> </a>    
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="rights">
            <div class="container">
                <div class="row m-0">
                    <div class="col-md-6">
                        <p>{{ $t("rights") }}</p>
                    </div>
                    <div class="col-md-6">
                        <ul class="links_list">
                            <li v-for="main_link in footer_menu" :key="main_link.key">
                                <router-link :to="{ path: main_link.item_url }"> {{ main_link.item_title }}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import axios from 'axios'
import Button from "./Button.vue";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({});
const base_url = process.env.VUE_APP_BASE_URL;

export default {
    name: 'Footer',
    components: {
        Button,
    },
    data: function () {
    return {
      footer_sections_left: [],
      footer_sections_right:[],
      footer_menu:[],
      footer_social_media:[],
      contact_data:[],
      phone_data:[],
    };
  },
  beforeMount() {
    this.get_footer_sections_left();
    this.get_footer_sections_right();
    this.get_footer_menu();
    this.get_footer_social_media();
    this.get_contact_data();
    this.get_phone_data();
  },
  methods: {
    get_footer_sections_left() {
      var params = {
        slug:"footer_left_text_box",
        locale: localStorage.getItem('lang'),
      };
      axios
        .get(base_url + "/getSectionBySlug", { params: params })

        .then((response) => {
          this.footer_sections_left = response.data.data;
        })
        .catch((error) => {
          let res = error.response.data;
          toaster.error(res.message);
        });
    },
    get_footer_sections_right() {
      var params = {
        slug:"footer_right_text_box",
        locale: localStorage.getItem('lang'),
      };
      axios
        .get(base_url + "/getSectionBySlug", { params: params })

        .then((response) => {
          this.footer_sections_right = response.data.data;
        })
        .catch((error) => {
          let res = error.response.data;
          toaster.error(res.message);
        });
    },
    get_footer_menu() {
        var params = {
            // slug: "footer_menu",
            locale: localStorage.getItem('lang')
        };
        axios
            .get(base_url + "/getMenuBySlug/footer_menu?", { params: params })
            .then((response) => {
                this.footer_menu = response.data.data;
            })
            .catch((error) => {
                let res = error.response;
                toaster.error();
            });
    },
    get_footer_social_media() {
      var params = {
        slug:"footer_social_media",
        // locale: localStorage.getItem('lang'),
      };
      axios
        .get(base_url + "/getSectionBySlug", { params: params })

        .then((response) => {
          this.footer_social_media = response.data.data;
        })
        .catch((error) => {
          let res = error.response.data.data;
          toaster.error(res.message);
        });
    },
    get_contact_data(){
        var params = {
        locale: localStorage.getItem('lang'),
      };
      axios
        .get(base_url + "/get_setting/contact_email", { params: params })

        .then((response) => {
          this.contact_data = response.data.data;
        })
        .catch((error) => {
          let res = error.response.data.data;
          toaster.error(res.message);
        }); 
    },
    get_phone_data(){
        var params = {
        locale: localStorage.getItem('lang'),
      };
      axios
        .get(base_url + "/get_setting/contact_phone", { params: params })

        .then((response) => {
          this.phone_data = response.data.data;
        })
        .catch((error) => {
          let res = error.response.data.data;
          toaster.error(res.message);
        }); 
    }
  },
    
}
</script>

<style scoped>
footer {
    background-color: var(--color);
    transition: 0.3s ease-in-out;
    padding-top: 70px;
    font-family: "Poppins";
}
.footer :where(h1, h2, h3, h4, h5, h6) {
    font-size: 1.25rem;
    font-weight: bold;
    color: white !important;
    margin-bottom: 1rem;
}

.footer p {
    color: white;
    font-size: 16px;
    max-width: 400px;
}
.contact_details{
    background-color: var(--mainColor);
    max-width: 500px;
    padding: 19px 10px;
}
.contact_details h3 {
    color: var(--color) !important;
    font-size: 18px !important;
    margin-bottom: 10px;
}
.contact_details span a{
    color: #282938c7 !important;
    font-size: 16px !important;
    font-weight: normal !important;
}
.footer h4{
    font-size: 48px;
    font-weight: 600;
}
.footer h5{
    font-size: 16px !important;
    color: #F4F6FC !important;
    opacity: .8 !important;
    max-width: 400px;
    font-weight: 100;
    line-height: 24px;
}
.footer .social_media {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer .social_media li {
    margin-bottom: 0.5rem;
    display: inline-block;
}

.footer .social_media li a {
    color: white;
    font-weight: lighter;
    font-size: 20px;
    text-decoration: none;
}

.footer .svg-inline--fa {
    width: 35px;
}

.footer form button {
    width: 100%;
}
.rights{
    background-color: #fff;
    padding: 23px 20px;
}
.rights p {
    font-size: 15px;
    color: var(--color);
    background-color: #fff;
    margin: 0;
}
.links_list{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 0;
}
.links_list li{
    padding: 0 11px;
}
.links_list li a{
    color: #282938;
    font-size: 16px;
}
@media (max-width: 768px) {
    .footer {
        grid-template-columns: 1fr;
        justify-content: center;
    }

    .footer .footer-column {
        text-align: center;
    }

    .footer ul li a {
        justify-content: center;
    }
}
</style>